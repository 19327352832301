/** @format */

import { getCompany, getFooter, getNavigation, getTranslations } from '@/lib/contenful.api';
import { Company, Menu, Translations } from '@/types/page';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Document } from '@contentful/rich-text-types';
import { Entry } from 'contentful';
import { GetStaticProps, NextPage } from 'next';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { PageWrapper } from '../components/PageWrapper';
import { InboundLink } from '../components/ui/Link';
import { getFirstContentfulEntry } from '../lib/contentful';
import { LinkEntry } from '../types/shared';

const metadata = {
  title: 'Joivy - 404',
  description: '',
};

const Custom404: NextPage<Props> = ({ content, navigation, footer, company, translations }) => {
  const { locale } = useRouter();
  const { text, link } = content;
  return (
    <PageWrapper
      meta={metadata}
      pageType="404"
      navigation={navigation}
      footer={footer}
      company={company}
      translations={translations}
      slug="404"
    >
      <Styled404>
        {documentToReactComponents(text)}
        {link && (
          <InboundLink href={link.fields.url} locale={locale}>
            {link.fields.label}
          </InboundLink>
        )}
      </Styled404>
    </PageWrapper>
  );
};

export default Custom404;

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const localeToFetch = () => {
    if (!locale) return 'en';
    if (locale === 'default') return 'en';
    return locale;
  };
  const content = await getFirstContentfulEntry('errorPage', localeToFetch());
  const navigation = await getNavigation(localeToFetch());
  const footer = await getFooter(localeToFetch());
  const company = await getCompany(localeToFetch());
  const translations = await getTranslations(localeToFetch());

  return {
    props: {
      content,
      navigation,
      footer,
      company,
      translations,
    },
  };
};

type Props = {
  content: {
    text: Document;
    link?: LinkEntry;
  };
  navigation: Entry<Menu>;
  footer: Entry<Menu>;
  company: Entry<Company>;
  translations: Entry<Translations>;
};

const Styled404 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 1rem;
  padding-block: 4rem;
  padding-inline: clamp(1.625rem, 0.75rem + 4.375vw, 3.375rem) 1.188rem;

  h1 {
    color: ${({ theme }) => theme.color.teal.dark};
    font-size: ${({ theme }) => `clamp(${theme.font.size[2.0625]}, 1.125vw + 1.9rem, ${theme.font.size[3.3125]});`};
    line-height: clamp(2.975rem, 1.25vw + 2.725rem, 4.225rem);
  }

  p {
    font-size: ${({ theme }) => theme.font.size[1.25]};
    color: ${({ theme }) => theme.color.teal.dark};
  }

  a {
    font-size: ${({ theme }) => theme.font.size[1]};
    color: ${({ theme }) => theme.color.white};
    background-color: ${({ theme }) => theme.color.teal.dark};
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 2em;

    border-radius: 0.25rem;
    padding: 1rem 2rem;

    &:hover,
    &[data-page-active='true'] {
      background-color: ${({ theme }) => theme.color.teal.light};
    }
  }
`;
